import './App.css';
import RouterWrap from './routers'

function App() {
  return (
    <div id="router">
      <RouterWrap />
    </div>
  );
}

export default App;
