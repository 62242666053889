import React, { Component } from "react";
import { WhiteSpace, Badge, Toast, Tabs, List, DatePicker, Picker, Flex, Result, PullToRefresh, Modal } from 'antd-mobile';
import { Chart, Line, Scale, Tooltip, Point } from 'ant-design-mobile-chart';
import { axiosPost } from '../Utils/RequestUtil';
import { relkey_bx as relkey } from '../Utils/StringUtil';
import moment from "moment";

const types = [{
    value: '01',
    label: '晨检'
}, {
    value: '02',
    label: '午检'
}, {
    value: '03',
    label: '晚检'
}];
const myImg = src => <img src={src} className="spe" alt="" />;

export default class Temperature extends Component {
    state = {
        docked: false,
        sidebar: [{ CodeBS: 'HYBM', CodeALLID: 'HYBM', CodeAllName: '学生', value: 'HYBM', label: '学生', children: [] }, { CodeBS: 'BMXX', CodeALLID: 'BMXX', CodeAllName: '教师', value: 'BMXX', label: '教师', children: [] }],
        current: { CodeBS: 'HYBM', CodeALLID: 'HYBM', CodeAllName: '学生', value: 'HYBM', label: '学生', children: [] },
        date: new Date(),
        type: ['01'],
        org: ['HYBM', ''],
        getType: '0',
        userGetType: '3',
        orgValue: '',
        getState: '1',
        index: 0,
        size: 50,
        tabs: [
            { title: <Badge text={'0'}>已提交</Badge> },
            { title: <Badge text={'0'}>未提交</Badge> },
        ],
        dataList: [],
        height: document.documentElement.clientHeight - 170,
        width: document.documentElement.clientWidth,
        refreshing: false,
        userDataList: [],
        chartVisible: false,
        imageVisible: false,
        imageSrc: ''
    }
    componentDidMount() {
        let newSidebar = [];
        this.state.sidebar.forEach(element => {
            Toast.loading('获取数据中', 60, null, true);
            let param = {
                relkey: relkey,
                model: '',
                flag: element.CodeBS
            }
            axiosPost(0, '', param, 'Data', (response) => {
                if (response.code === 0) {
                    let sidebar = [{ CodeBS: element.CodeBS, CodeALLID: '', CodeAllName: '全部', value: '', label: '全部', children: [] }];
                    response.data.list.forEach(el => {
                        el.label = el.CodeAllName;
                        el.value = el.CodeALLID;
                        let elChild = [{ CodeBS: element.CodeBS, CodeALLID: '', CodeAllName: '全部', value: '', label: '全部', children: [] }];
                        if (el.children.length > 0) {
                            el.children.forEach(elchilditem => {
                                elchilditem.label = elchilditem.CodeAllName;
                                elchilditem.value = elchilditem.CodeALLID;
                                elChild.push(elchilditem);
                            });
                        }
                        el.children = elChild;
                        sidebar.push(el);
                    });
                    element.children = sidebar;
                }
                newSidebar.push(element);
                this.setState({ sidebar: newSidebar }, () => {
                    Toast.hide();
                    this.getData();
                })
            });
        });
    }
    getData = () => {
        Toast.loading('获取数据中', 60, null, true);
        let param = {
            relkey: relkey,
            type: this.state.getType,
            org: this.state.orgValue,
            state: this.state.getState,
            date: moment(this.state.date).format("YYYY-MM-DD"),
            check: this.state.type[0],
            index: this.state.index,
            size: this.state.size
        }
        axiosPost(1, '', param, 'Temperature', (response) => {
            this.setState({ refreshing: false });
            if (response.code === 0 || response.code === 1) {
                this.setState({
                    tabs: [
                        { title: <Badge overflowCount={99999} text={response.data.committed}>已提交</Badge> },
                        { title: <Badge overflowCount={99999} text={response.data.uncommitted}>未提交</Badge> },
                    ], dataList: this.state.dataList.concat(response.data.list)
                })
            }
            Toast.hide();
        });
    }
    onChangeOrg = (org) => {
        let gettype = '0';
        let orgvalue = '';
        let usergettype = '3';
        if (org[0] === 'BMXX') {
            gettype = '1';
            usergettype = '2';
        }
        if (org.length === 2) {
            orgvalue = org[1];
        }
        if (org.length === 3) {
            if (org[2] === '') {
                orgvalue = org[1];
            } else {
                orgvalue = org[2];
            }
        }
        this.setState({
            org: org,
            getType: gettype,
            userGetType: usergettype,
            orgValue: orgvalue,
            dataList: [],
            index: 0
        }, () => {
            this.getData();
        });

    }
    onChangeType = (type) => {
        this.setState({
            type: type,
            dataList: [],
            index: 0
        }, () => {
            this.getData();
        });
    }
    onTabChange = (tab, index) => {
        if (index === 0) {
            this.setState({ getState: '1', dataList: [], index: 0 }, () => {
                this.getData();
            })
        } else {
            this.setState({ getState: '0', dataList: [], index: 0 }, () => {
                this.getData();
            })
        }
    }
    onUpRefresh = () => {
        this.setState({ refreshing: true, index: this.state.index + 1 }, () => {
            this.getData();
        });

    }
    onStudentClick = (item) => {
        Toast.loading('获取数据中', 60, null, true);
        let param = {
            relkey: relkey,
            type: this.state.userGetType,
            org: '',
            state: '1',
            date: '',
            check: '',
            key: item.key,
            index: 0,
            size: 5
        }
        axiosPost(1, '', param, 'Temperature', (response) => {
            Toast.hide();
            if (response.code === 0) {
                var userDataList = [];
                var index = 1;
                for (var i = response.data.list.length - 1; i >= 0; i--) {
                    var item = response.data.list[i];
                    item.reportDateTimestamp = item.date + '.' + parseInt(index);
                    item.codeType = item.name;
                    item.rate = parseFloat(item.temperature).toFixed(1);
                    userDataList.push(item);
                    index++;
                }
                this.setState({ userDataList: userDataList }, () => {
                    this.setState({ chartVisible: true });
                });
            }
            else {
                Toast.fail(response.message, 1);
            }
        });
    }
    onDetailClose = () => {
        this.setState({ chartVisible: false });
    }

    onTempClick = (item) => {
        this.setState({ imageSrc: item.tempImage }, () => {
            this.setState({ imageVisible: true });
        });
    }
    onImageClose = () => {
        this.setState({ imageVisible: false });
    }
    render() {
        return (
            <div id="body" style={{ height: '100%' }}>
                <WhiteSpace />
                <Flex>
                    <Flex.Item className="org">
                        <Picker extra="请选择"
                            data={this.state.sidebar}
                            title="部门"
                            value={this.state.org}
                            onChange={this.onChangeOrg}
                        >
                            <List.Item arrow="horizontal">部门</List.Item>
                        </Picker>
                    </Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex>
                    <Flex.Item className="listline">
                        <DatePicker
                            mode="date"
                            title="选择日期"
                            extra="Optional"
                            value={this.state.date}
                            onChange={date => this.setState({ date, dataList: [], index: 0 }, () => { this.getData(); })}
                        >
                            <List.Item arrow="horizontal"></List.Item>
                        </DatePicker>
                    </Flex.Item>
                    <Flex.Item className="listline">
                        <Picker
                            data={types}
                            value={this.state.type}
                            cols={1}
                            onChange={this.onChangeType}
                        >
                            <List.Item arrow="horizontal"></List.Item>
                        </Picker>
                    </Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex>
                    <Flex.Item>
                        <Tabs tabs={this.state.tabs}
                            initialPage={0}
                            onChange={this.onTabChange}
                        >
                            <div style={{ height: 'auto', width: '100%', backgroundColor: '#fff' }}>
                                <PullToRefresh
                                    damping={60}
                                    ref={el => this.ptr = el}
                                    style={{
                                        height: this.state.height,
                                        overflow: 'auto',
                                    }}
                                    indicator={{ deactivate: '上拉可以刷新' }}
                                    direction={'up'}
                                    refreshing={this.state.refreshing}
                                    onRefresh={this.onUpRefresh}
                                >
                                    <Flex style={{ textAlign: 'center', padding: 2, lineHeight: '30px', backgroundColor: '#f0f0f0' }}>
                                        <Flex.Item>
                                            部门
                                            </Flex.Item>
                                        <Flex.Item>
                                            姓名
                                            </Flex.Item>
                                        <Flex.Item>
                                            体温
                                            </Flex.Item>
                                    </Flex>
                                    {
                                        this.state.dataList.length > 0 ? this.state.dataList.map((item, index) => {
                                            let backgroundColor = index % 2 !== 0 ? '#f8f8f8' : '#fff';
                                            return (
                                                <Flex key={item.key + '' + index} style={{ textAlign: 'center', padding: 2, lineHeight: '30px', backgroundColor: backgroundColor }}>
                                                    <Flex.Item>
                                                        {item.orgName}
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={this.onStudentClick.bind(this, item)}>{item.name}</a>
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={this.onTempClick.bind(this, item)}>{item.temperature}</a>
                                                    </Flex.Item>
                                                </Flex>
                                            )
                                        }) : <Result
                                                img={myImg('https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg')}
                                                title="暂无数据"
                                                message="未获取到数据"
                                            />
                                    }
                                </PullToRefresh>
                            </div>
                            <div style={{ height: 'auto', width: '100%', backgroundColor: '#fff' }}>
                                <PullToRefresh
                                    damping={60}
                                    ref={el => this.ptr = el}
                                    style={{
                                        height: this.state.height,
                                        overflow: 'auto',
                                    }}
                                    indicator={{ deactivate: '上拉可以刷新' }}
                                    direction={'up'}
                                    refreshing={this.state.refreshing}
                                    onRefresh={this.onUpRefresh}
                                >
                                    <Flex style={{ textAlign: 'center', padding: 2, lineHeight: '30px', backgroundColor: '#f0f0f0' }}>
                                        <Flex.Item>
                                            部门
                                            </Flex.Item>
                                        <Flex.Item>
                                            姓名
                                            </Flex.Item>
                                        <Flex.Item>
                                            体温
                                            </Flex.Item>
                                    </Flex>
                                    {
                                        this.state.dataList.length > 0 ? this.state.dataList.map((item, index) => {
                                            let backgroundColor = index % 2 !== 0 ? '#f8f8f8' : '#fff';
                                            return (
                                                <Flex key={item.key + '' + index} style={{ textAlign: 'center', padding: 2, lineHeight: '30px', backgroundColor: backgroundColor }}>
                                                    <Flex.Item>
                                                        {item.orgName}
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={this.onStudentClick.bind(this, item)}>{item.name}</a>
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={this.onTempClick.bind(this, item)}>{item.temperature}</a>
                                                    </Flex.Item>
                                                </Flex>
                                            )
                                        }) : <Result
                                                img={myImg('https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg')}
                                                title="暂无数据"
                                                message="未获取到数据"
                                            />
                                    }
                                </PullToRefresh>
                                <Modal
                                    popup
                                    visible={this.state.chartVisible}
                                    onClose={this.onDetailClose}
                                    animationType="slide-up"
                                    afterClose={() => { }}
                                >
                                    {
                                        this.state.userDataList.length > 0 ?
                                            (<div>
                                                <Chart source={this.state.userDataList} width={this.state.width - 10} height="300" tooltip={true} pixelRatio={window.devicePixelRatio}>
                                                    <Line position="reportDateTimestamp*rate" color="codeType" />
                                                    <Scale field="reportDateTimestamp" tickCount={5} style={{ fontSize: '8px' }} type="cat" />
                                                    <Scale field="rate" type="linear" max={43} min={34} tickCount={10} formatter={(rate) => `${parseFloat(rate).toFixed(1)}`} />
                                                    <Tooltip
                                                        showCrosshairs={true}
                                                        alwaysShow={true}
                                                        crosshairsStyle={{
                                                            stroke: 'rgba(0, 0, 0, 0.25)',
                                                            lineWidth: 1
                                                        }}
                                                        onShow={(ev) => {
                                                            var items = ev.items;
                                                            items[0].name = "体温";
                                                            items[0].rate = items[0].rate+'℃';
                                                        }}
                                                    />
                                                    <Point position="reportDateTimestamp*rate"/>
                                                </Chart>
                                            </div>) : <Result
                                                img={myImg('https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg')}
                                                title="暂无数据"
                                                message="未获取到数据"
                                            />
                                    }
                                </Modal>
                                <Modal
                                    popup
                                    visible={this.state.imageVisible}
                                    onClose={this.onImageClose}
                                    animationType="slide-up"
                                    afterClose={() => { }}
                                    style={{ textAlign: 'center' }}
                                >
                                    <img src={this.state.imageSrc} alt='' style={{ width: '50%' }} />
                                </Modal>
                            </div>
                        </Tabs>
                    </Flex.Item>
                </Flex>
            </div>
        )
    }
}