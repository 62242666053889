export const relkey_sy='85738E77-53C6-4D06-A2C4-DB63EB9DCBBB';
export const relkey_bx='85738E77-53C6-4D06-A2C4-DB63EB9DCAAA';
export function numberToMoney(number) {
     var nstr = number + "";
     var money = nstr.split('.')[0].replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
          return s + ','
     });
     var moneyArr = nstr.split('.');
     if (moneyArr.length > 1) {
          if (moneyArr[1].length > 1) {
               return money + '.' + moneyArr[1];
          }
          return money + '.' + moneyArr[1] + '0';
     }
     return money + '.00'
}

export function numberToInt(number) {
     return number + "".replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
          return s + ','
     });
}
export function isEmptyObject(obj) {
     for (var key in obj) {
          return false;
     }
     return true;
}
export function getUrlPara(name) {
     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
     var r = window.location.search.substr(1).match(reg);
     if (r != null) return unescape(r[2]); return null;
}