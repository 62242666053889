import React,{Component} from "react";
import {BrowserRouter,Route,Switch} from "react-router-dom";
import Temperature from "./Pages/Temperature";
export default class RouterWrap extends Component{
    render(){
        return (
            <div id="router">
                <BrowserRouter>
                    <Switch>
                        <Route path="/temperature" component={Temperature}/>
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}